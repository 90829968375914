.react-spinner-loader-svg-calLoader {
    width: 230px;
    height: 230px;
    transform-origin: 115px 115px;
    animation: 1.4s linear infinite loader-spin;
    -webkit-animation: 1.4s linear infinite loader-spin;
}

.react-spinner-loader-svg-cal-loader__path {

    animation: 1.4s ease-in-out infinite loader-path;
    -webkit-animation: 1.4s ease-in-out infinite loader-path;
}

@keyframes loader-spin {
    to {
        transform: rotate(360deg);
    }
}

@keyframes loader-path {
    0% {
        stroke-dasharray: 0, 580, 0, 0, 0, 0, 0, 0, 0;
    }

    50% {
        stroke-dasharray: 0, 450, 10, 30, 10, 30, 10, 30, 10;
    }

    100% {
        stroke-dasharray: 0, 580, 0, 0, 0, 0, 0, 0, 0;
    }
}
