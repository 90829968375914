.react-spinner-loader-swing div {
    border-radius: 50%;
    float: left;
    height: 1em;
    width: 1em;
}

.react-spinner-loader-swing div:nth-of-type(1) {
    background: -webkit-linear-gradient(left, #385c78 0%, #325774 100%);
    background: linear-gradient(to right, #385c78 0%, #325774 100%);
}

.react-spinner-loader-swing div:nth-of-type(2) {
    background: -webkit-linear-gradient(left, #325774 0%, #47536a 100%);
    background: linear-gradient(to right, #325774 0%, #47536a 100%);
}

.react-spinner-loader-swing div:nth-of-type(3) {
    background: -webkit-linear-gradient(left, #4a5369 0%, #6b4d59 100%);
    background: linear-gradient(to right, #4a5369 0%, #6b4d59 100%);
}

.react-spinner-loader-swing div:nth-of-type(4) {
    background: -webkit-linear-gradient(left, #744c55 0%, #954646 100%);
    background: linear-gradient(to right, #744c55 0%, #954646 100%);
}

.react-spinner-loader-swing div:nth-of-type(5) {
    background: -webkit-linear-gradient(left, #9c4543 0%, #bb4034 100%);
    background: linear-gradient(to right, #9c4543 0%, #bb4034 100%);
}

.react-spinner-loader-swing div:nth-of-type(6) {
    background: -webkit-linear-gradient(left, #c33f31 0%, #d83b27 100%);
    background: linear-gradient(to right, #c33f31 0%, #d83b27 100%);
}

.react-spinner-loader-swing div:nth-of-type(7) {
    background: -webkit-linear-gradient(left, #da3b26 0%, #db412c 100%);
    background: linear-gradient(to right, #da3b26 0%, #db412c 100%);
}

.react-spinner-loader-shadow {
    clear: left;
    padding-top: 1.5em;
}

.react-spinner-loader-shadow div {
    -webkit-filter: blur(1px);
    filter: blur(1px);
    float: left;
    width: 1em;
    height: .25em;
    border-radius: 50%;
    background: #e3dbd2;
}

.react-spinner-loader-shadow .react-spinner-loader-shadow-l {
    background: #d5d8d6;
}

.react-spinner-loader-shadow .react-spinner-loader-shadow-r {
    background: #eed3ca;
}

@-webkit-keyframes ball-l {

    0%,
    50% {
        -webkit-transform: rotate(0) translateX(0);
        transform: rotate(0) translateX(0);
    }

    100% {
        -webkit-transform: rotate(50deg) translateX(-2.5em);
        transform: rotate(50deg) translateX(-2.5em);
    }
}

@keyframes ball-l {

    0%,
    50% {
        -webkit-transform: rotate(0) translate(0);
        transform: rotate(0) translateX(0);
    }

    100% {
        -webkit-transform: rotate(50deg) translateX(-2.5em);
        transform: rotate(50deg) translateX(-2.5em);
    }
}

@-webkit-keyframes ball-r {
    0% {
        -webkit-transform: rotate(-50deg) translateX(2.5em);
        transform: rotate(-50deg) translateX(2.5em);
    }

    50%,
    100% {
        -webkit-transform: rotate(0) translateX(0);
        transform: rotate(0) translateX(0);
    }
}

@keyframes ball-r {
    0% {
        -webkit-transform: rotate(-50deg) translateX(2.5em);
        transform: rotate(-50deg) translateX(2.5em);
    }

    50%,
    100% {
        -webkit-transform: rotate(0) translateX(0);
        transform: rotate(0) translateX(0)
    }
}

@-webkit-keyframes shadow-l-n {

    0%,
    50% {
        opacity: .5;
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }

    100% {
        opacity: .125;
        -webkit-transform: translateX(-1.57em);
        transform: translateX(-1.75em);
    }
}

@keyframes shadow-l-n {

    0%,
    50% {
        opacity: .5;
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }

    100% {
        opacity: .125;
        -webkit-transform: translateX(-1.75);
        transform: translateX(-1.75em);
    }
}

@-webkit-keyframes shadow-r-n {
    0% {
        opacity: .125;
        -webkit-transform: translateX(1.75em);
        transform: translateX(1.75em);
    }

    50%,
    100% {
        opacity: .5;
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
}

@keyframes shadow-r-n {
    0% {
        opacity: .125;
        -webkit-transform: translateX(1.75em);
        transform: translateX(1.75em);
    }

    50%,
    100% {
        opacity: .5;
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
}

.react-spinner-loader-swing-l {
    -webkit-animation: ball-l .425s ease-in-out infinite alternate;
    animation: ball-l .425s ease-in-out infinite alternate;
}

.react-spinner-loader-swing-r {
    -webkit-animation: ball-r .425s ease-in-out infinite alternate;
    animation: ball-r .425s ease-in-out infinite alternate;
}

.react-spinner-loader-shadow-l {
    -webkit-animation: shadow-l-n .425s ease-in-out infinite alternate;
    animation: shadow-l-n .425s ease-in-out infinite alternate;
}

.react-spinner-loader-shadow-r {
    -webkit-animation: shadow-r-n .425s ease-in-out infinite alternate;
    animation: shadow-r-n .425s ease-in-out infinite alternate;
}