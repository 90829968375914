.react-spinner-loader-svg svg {
    -webkit-transform-origin: 50% 65%;
    transform-origin: 50% 65%;
}

.react-spinner-loader-svg svg polygon {
    stroke-dasharray: 17;
    -webkit-animation: dash 2.5s cubic-bezier(0.35, 0.04, 0.63, 0.95) infinite;
    animation: dash 2.5s cubic-bezier(0.35, 0.04, 0.63, 0.95) infinite;
}

@-webkit-keyframes dash {
    to {
        stroke-dashoffset: 136;
    }
}

@keyframes dash {
    to {
        stroke-dashoffset: 136;
    }
}

@-webkit-keyframes rotate {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes rotate {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}